import React from "react";
import logo from "../res/Logo/CHRealty_Logo_Horizontal_Transparent_Black.png";
import { NavLink} from "react-router-dom";

export default class Navbar extends React.Component {
    _mounted;
    constructor(props){
        super(props);

    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <nav className="navbar navbar-expand-md shadow-sm">
      
        <a className="navbar-brand" href="#"><img className="logo" src={logo}/></a>
    
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span><i className="material-icons align-middle">menu</i></span>
        </button>
      
  
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item ">
              <NavLink to="/inicio" activeClassName={"active-nav"}  className={`nav-link px-3 py-4`}>
            <span className="ml-1">INICIO</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink to="/servicios" activeClassName="active-nav" className={`nav-link px-3 py-4`}>
                 <span className="ml-1">SERVICIOS</span>
              </NavLink>
            </li>
            <li className="nav-item ">
               <NavLink to="/rentas" activeClassName="active-nav" className={`nav-link px-3 py-4`}>
                   <span className="ml-1">RENTAS</span>
               </NavLink>
            </li>

            <li className="nav-item ">
                 <NavLink to="/ventas" activeClassName="active-nav" className={`nav-link px-3 py-4`}>
                   <span className="ml-1">VENTAS</span>
                </NavLink>
            </li>

            <li className="nav-item ">
               <NavLink to="/rentar-propiedad" activeClassName="active-nav" className={`nav-link px-3 py-4`}>
                   <span className="ml-1">RENTAR PROPIEDAD</span>
               </NavLink>
            </li>

            <li className="nav-item ">
               <NavLink to="/vender-propiedad" activeClassName="active-nav" className={`nav-link px-3 py-4`}>
                   <span className="ml-1">VENDER PROPIEDAD</span>
               </NavLink>
            </li>

            <li className="nav-item ">
               <NavLink to="/contacto" activeClassName="active-nav" className={`nav-link px-3 py-4`}>
                   <span className="ml-1">CONTACTAR</span>
               </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    }

}