import React,{Suspense,lazy} from 'react';
import './styles/sass/App.scss';
import Navbar from "./components/Navbar";
import CircleLoader from "./components/Loaders/CircleLoader";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Switch, NavLink, Route, Redirect} from "react-router-dom";
import { Position, Toast, Toaster, Classes} from "@blueprintjs/core";
import firebase from "./utils/firebaseSetUp";


const Home = lazy(() => import("./components/Home"));
const Services = lazy(() => import("./components/Services"));
const RentsNSales = lazy(() => import("./components/Rents"));
const RentProperty = lazy(() => import("./components/RentProperty"));
const Sales = lazy(() => import("./components/Sales"));
const PropertyView = lazy(() => import("./components/PropertyView"));
const SellProperty = lazy(() => import("./components/SellProperty"));
const Contact = lazy(() => import("./components/Contact"));
const Admin = lazy(() => import("./components/Admin"));
const TermsConditions = lazy(() => import("./components/Terms&Conditions"));


class App extends React.Component {
  _mounted;
  constructor(props){
    super(props);

    this.state = {
      screen:0,
      propertyData:null,
      toasts:[],
      admin:false,

      home:null,
      services:null,
      rents:null,
      onsale:null,
      RentProperty:null,
      SellProperty:null,
      contact:null,
      terms:null
    }

    this.toaster = {};
        this.refHandlers = {
            toaster:(ref) => {this.toaster = ref},
        }

  }

  getContact = () => {
    firebase.firestore().collection("sections").doc("contact").get()
    .then(doc => {
      let data = doc.data();
      data.id = doc.id;

      if(this._mounted){
        this.setState({
          contact:data
        })
      }
    })
    .catch(e => {
      console.log(e);
      this.addToast("Algo salió mal");
    })
  }

  componentDidMount(){
    this._mounted = true;

    this.getContact();
    
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}
  
setAdmin =(bool) => {
  if(this._mounted){
    this.setState({
      admin:bool
    })
  }
}

  render(){
    return <div>
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
                </Toaster>
      <Suspense fallback={<CircleLoader/>}>
        <Router>
        {this.state.admin === false?<Navbar screen={this.state.screen}
      />:null}
          <Switch>
         
            <Route exact path="/admin" render={() => <Admin setAdmin={this.setAdmin} addToast={this.addToast}/>}/>
            <Route exact path="/" render={() => <Redirect to="/inicio"/>}/>
            <Route exact path="/inicio" render={() => <Home setData={data => this.setState({home:data})} data={this.state.home} setAdmin={this.setAdmin} contact={this.state.contact}  addToast={this.addToast}/>} />
            <Route exact path="/servicios" render={() => <Services setData={data => this.setState({services:data})} data={this.state.services} contact={this.state.contact}  setAdmin={this.setAdmin} addToast={this.addToast}/>}/>
            <Route exact path="/rentas" render={() => <RentsNSales setData={data => this.setState({rents:data})} data={this.state.rents} contact={this.state.contact} setAdmin={this.setAdmin} addToast={this.addToast}/>}/>
            <Route exact path="/ventas"  render={() => <Sales setData={data => this.setState({onsale:data})} data={this.state.onsale} contact={this.state.contact} setAdmin={this.setAdmin} addToast={this.addToast} />} />
            <Route exact path="/rentar-propiedad" render={() => <RentProperty setData={data => this.setState({RentProperty:data})} data={this.state.RentProperty} contact={this.state.contact} setAdmin={this.setAdmin} addToast={this.addToast}/>}/>
            <Route exact path="/vender-propiedad" render={() => <SellProperty setData={data => this.setState({SellProperty:data})} data={this.state.SellProperty} contact={this.state.contact} setAdmin={this.setAdmin} addToast={this.addToast}/>}/>
            <Route exact path="/contacto" render={() => <Contact data={this.state.contact} contact={this.state.contact} setAdmin={this.setAdmin} addToast={this.addToast}/>}/>
            <Route exact path="/:type/:propertyID" render={({match}) =>  <PropertyView addToast={this.addToast} contact={this.state.contact} data={this.state.propertyData} match={match}/>}/>
            <Route exact path="/terminos-y-condiciones"  render={() => <TermsConditions data={this.state.terms} setData={data => this.setState({terms:data})} addToast={this.props.addToast} setAdmin={this.setAdmin}/>}/>
         </Switch>
         {this.state.contact !== null?<Footer contact={this.state.contact}/>:null}
        </Router>
      </Suspense>
      
    </div>
  }


}

export default App;
