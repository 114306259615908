import React from "react";

import logo from "../res/Logo/CHRealty_Logo_Horizontal_Transparent_White.png";
import { NavLink} from "react-router-dom";

export default class Footer extends React.Component {
    constructor(props){
        super(props)
    }

    render(){
        return <footer className="footer-area footer--light">
        <div className="footer-big">
        
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <div className="footer-widget">
                  <div className="widget-about">
                    <img src={logo} alt="" className="img-fluid"/>
    <p>{this.props.contact.address}</p>
                    <ul className="contact-details">
                      <li>
                         Contáctanos:
                        <ul className=" mt-2">
                        {this.props.contact.phones.map((e,i) => {
                          return <li><a href={`tel:${e}`} key={i} target="_blank">{e}</a></li>
                        })}
                        </ul>
                        
                      </li>
                      <li>
                        
                       
                        <ul className=" mt-2">
                        {this.props.contact.emails.map((e,i) => {
                          return <li><a href={`mailto:${e}`} key={i} target="_blank">{e}</a></li>
                        })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
        
              </div>
           
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu footer-menu--1">
                    <h4 className="footer-widget-title">Enlaces</h4>
                    <ul>
                      <li>
                        <NavLink to="/inicio">Inicio</NavLink>
                      </li>
                      <li>
                        <NavLink to="/servicios">Servicios</NavLink>
                      </li>
                      <li>
                        <NavLink to="/rentas">Rentas</NavLink>
                      </li>
                      <li>
                        <NavLink to="/ventas">Ventas</NavLink>
                      </li>
                      <li>
                        <NavLink to="/rentar-propiedad">Rentar Propiedad</NavLink>
                      </li>
                      <li>
                        <NavLink to="/vender-propiedad">Vender Propiedad</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contacto">Contactar</NavLink>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              
              </div>
           
      
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu">
                    <h4 className="footer-widget-title">Nuestra Empresa</h4>
                    <ul>
                      <li>
                        <a href="/inicio#acerca-de-nosotros">Sobre Nosotros</a>
                      </li>
                      <li>
                        <a href="/servicios">Que Ofrecemos?</a>
                      </li>
                      <li>
                        <a href="/inicio#lo-que-dicen-nuestros-clientes">Lo que dicen nuestros clientes</a>
                      </li>
                      <li>
                        <NavLink to="/contacto">Contáctanos</NavLink>
                      </li>
                    </ul>
                  </div>
                
                </div>
           
              </div>
      
      
              <div className="col-md-3 col-sm-4">
                <div className="footer-widget">
                  <div className="footer-menu no-padding">
                    <h4 className="footer-widget-title">Ayuda y Soporte</h4>
                    <ul>
          
                      <li>
                        <NavLink to="/terminos-y-condiciones">Términos &amp; Condiciones</NavLink>
                      </li>
                      <li>
                        <a href="/inicio#preguntas-frecuentes" to="/inicio">Preguntas Frecuentes</a>
                      </li>
                    </ul>
                  </div>
                 
                </div>
            
              </div>
              
      
            </div>
          
          </div>
      
        </div>
      
      
        <div className="mini-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-text">
                  <p>© 2020
                    <a href="#"> CH REALTY</a>. Todos los derechos reservados.
                  </p>
                </div>
      
                <div className="go_top">
                  <i className="material-icons align-middle">arrow_upward</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    }
}

