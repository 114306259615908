import firebase from "firebase";
import "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
    apiKey: "AIzaSyAD7S-7asrsvO_1ZyfT07YLfLFGLWyji2M",
    authDomain: "proyecto-inmobiliario-e91b2.firebaseapp.com",
    databaseURL: "https://proyecto-inmobiliario-e91b2.firebaseio.com",
    projectId: "proyecto-inmobiliario-e91b2",
    storageBucket: "proyecto-inmobiliario-e91b2.appspot.com",
    messagingSenderId: "1015931736589",
    appId: "1:1015931736589:web:9d746842a5beba4dd7bb44",
    measurementId: "G-2MYEBTC086"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;